<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-xl-6">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Impersonation Access
                            </h3>
                        </div>
                    </div>

                    <div class="kt-portlet__body">
                        <div class="form-group validated row mt-4 pl-0 pr-0 pt-4 w-100">
                            <label class="col-lg-3 col-form-label">
                                User:
                            </label>
                            <div class="col-lg-9">
                                <div class="input-group">
                                    <input
                                        v-model="impersonatorEmailSuffix"
                                        placeholder="user"
                                        type="text"
                                        class="form-control"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">@syncgrades.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group validated row w-100">
                            <label class="col-lg-3 col-form-label">
                                School:
                            </label>
                            <div class="col-lg-9">
                                <div class="input-group">
                                    <input
                                        v-model="impersonatorSchoolId"
                                        :placeholder="`e.g. 08X405`"
                                        type="text"
                                        class="form-control"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-primary"
                                            type="button"
                                            :disabled="!isValidImpersonator"
                                            @click.stop.prevent="addImpersonator()"
                                        >
                                            Add Access
                                        </button>
                                    </div>
                                </div>
                                <div
                                    v-if="!isValidImpersonator"
                                    class="invalid-warn-feedback"
                                >
                                    Please enter a valid extSchoolId and user
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget4 pt-4">
                            <div
                                v-for="(school, idx) in impersonators"
                                :key="`bg_${school.schoolId}`"
                                class="kt-widget4__item"
                            >
                                <div class="kt-widget4__pic kt-widget4__pic--pic">
                                    <span
                                        class="course-section-badge "
                                        :class="`color${Math.floor((school.externalSchoolId.charCodeAt(school.externalSchoolId.length - 1) + idx) % 10)}`"
                                        style="min-width: 80px; display: inline-block;"
                                    >
                                        {{ school.externalSchoolId }}
                                    </span>
                                </div>
                                <div class="kt-widget4__info">
                                    <router-link
                                        :to="{ name: 'CreateOrEditSchool', params: { externalSchoolId: school.externalSchoolId } }"
                                        class="kt-widget4__username"
                                    >
                                        {{ school.name }}
                                    </router-link>

                                    <p class="kt-widget4__text email-label">
                                        {{ school.userName }}
                                    </p>
                                    <p class="kt-widget4__text email-label">
                                        expires {{ school.expires.substr(0, 10) }}
                                    </p>
                                </div>
                                <a
                                    class="btn btn-clean btn-danger btn-sm pull-right"
                                    @click.stop.prevent="removeImpersonator(school)"
                                >
                                    <i class="la la-trash " /> Remove
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import async from 'async';
import * as network from '../../network';
import Types from '../../store/Types';

export default {
    name: 'ImpersonationAccess',
    components: {
    },
    data() {
        return {
            impersonators: [],
            impersonatorEmailSuffix: null,
            impersonatorSchoolId: null,
            extSchoolId: null,
        };
    },
    computed: {
        ...mapState({
            schoolTerm: (state) => state.user.school,
            externalDistrictId: (state) => state.user.school.externalDistrictId,
            user: (state) => state.user,
            schools: (state) => state.database.schools,
        }),
        selectedImpersonatorSchool() {
            const { impersonatorSchoolId, schools } = this;
            return schools.find((s) => s.externalSchoolId == (impersonatorSchoolId || '').toUpperCase()) || null;
        },
        isValidImpersonator() {
            const { selectedImpersonatorSchool, impersonatorEmailSuffix } = this;
            return Boolean(selectedImpersonatorSchool && impersonatorEmailSuffix);
        },
    },
    mounted() {
        if (this.schools.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
        }
        this.populate();
    },
    methods: {
        populate() {
            const { showError } = this;
            const v = this;
            async.auto({
                impersonators(next) {
                    network.users.listImpersonators({}, (err, res) => {
                        if (err) return next(err);
                        v.impersonators = res.impersonatorAccess;
                        next();
                    });
                },
            }, (err) => {
                if (err) return showError(err);
            });
        },
        addImpersonator() {
            const { showError, populate, selectedImpersonatorSchool } = this;
            const userName = this.impersonatorEmailSuffix;
            const params = {
                body: {
                    emailAddress: `${userName}@syncgrades.com`,
                    schoolId: selectedImpersonatorSchool.schoolId,
                    deleted: false,
                },
            };

            network.users.allowImpersonation(params, (err, res) => {
                if (err) return showError(err);
                populate();
            });
        },
        removeImpersonator(impersonator) {
            const { showError, populate } = this;
            const emailAddress = impersonator.userName;
            const params = {
                body: {
                    emailAddress,
                    schoolId: impersonator.schoolId,
                    deleted: true,
                },
            };

            network.users.allowImpersonation(params, (err) => {
                if (err) return showError(err);
                populate();
            });
        },
    },
};

</script>

<style scoped>
.email-label {
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: clip;
}
</style>
