var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-6" }, [
            _c("div", { staticClass: "kt-portlet" }, [
              _vm._m(0),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group validated row mt-4 pl-0 pr-0 pt-4 w-100",
                  },
                  [
                    _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                      _vm._v(" User: "),
                    ]),
                    _c("div", { staticClass: "col-lg-9" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.impersonatorEmailSuffix,
                              expression: "impersonatorEmailSuffix",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "user", type: "text" },
                          domProps: { value: _vm.impersonatorEmailSuffix },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.impersonatorEmailSuffix = $event.target.value
                            },
                          },
                        }),
                        _vm._m(1),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-group validated row w-100" }, [
                  _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                    _vm._v(" School: "),
                  ]),
                  _c("div", { staticClass: "col-lg-9" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.impersonatorSchoolId,
                            expression: "impersonatorSchoolId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: `e.g. 08X405`, type: "text" },
                        domProps: { value: _vm.impersonatorSchoolId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.impersonatorSchoolId = $event.target.value
                          },
                        },
                      }),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              disabled: !_vm.isValidImpersonator,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addImpersonator()
                              },
                            },
                          },
                          [_vm._v(" Add Access ")]
                        ),
                      ]),
                    ]),
                    !_vm.isValidImpersonator
                      ? _c("div", { staticClass: "invalid-warn-feedback" }, [
                          _vm._v(" Please enter a valid extSchoolId and user "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "kt-widget4 pt-4" },
                  _vm._l(_vm.impersonators, function (school, idx) {
                    return _c(
                      "div",
                      {
                        key: `bg_${school.schoolId}`,
                        staticClass: "kt-widget4__item",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kt-widget4__pic kt-widget4__pic--pic",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "course-section-badge",
                                class: `color${Math.floor(
                                  (school.externalSchoolId.charCodeAt(
                                    school.externalSchoolId.length - 1
                                  ) +
                                    idx) %
                                    10
                                )}`,
                                staticStyle: {
                                  "min-width": "80px",
                                  display: "inline-block",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(school.externalSchoolId) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "kt-widget4__info" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "kt-widget4__username",
                                attrs: {
                                  to: {
                                    name: "CreateOrEditSchool",
                                    params: {
                                      externalSchoolId: school.externalSchoolId,
                                    },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(school.name) + " ")]
                            ),
                            _c(
                              "p",
                              { staticClass: "kt-widget4__text email-label" },
                              [_vm._v(" " + _vm._s(school.userName) + " ")]
                            ),
                            _c(
                              "p",
                              { staticClass: "kt-widget4__text email-label" },
                              [
                                _vm._v(
                                  " expires " +
                                    _vm._s(school.expires.substr(0, 10)) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-clean btn-danger btn-sm pull-right",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.removeImpersonator(school)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-trash" }),
                            _vm._v(" Remove "),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Impersonation Access "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _vm._v("@syncgrades.com"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }